import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import { Button, Container } from 'reactstrap'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { PARTENARIAT_PAGE, DEVENIR_PARTENAIRE } from 'shared/routes'

interface PartnerImageSection {
  contributionUrl: string
  merchandiseUrl: string
}

const PartnerImageSection = ({ contributionUrl, merchandiseUrl }: PartnerImageSection): JSX.Element => {
  const language = useIntl().locale || 'fr'
  const devenirPartenaireUrlWithLocale = language === 'en' ? `/en${PARTENARIAT_PAGE}` : PARTENARIAT_PAGE

  return (
    <div id="partner-header" className="page-header sponsor">
      <div className="filter" />
      <Container>
        <div className="motto text-center">
          <h1>
            <FormattedMessage id="partnerpage_thank_you" />
          </h1>
          <br />
          <Button
            color="primary"
            stripHash
            tag={AnchorLink}
            to={`${devenirPartenaireUrlWithLocale}#${DEVENIR_PARTENAIRE}`}
          >
            <FormattedMessage id="partnerpage_become_partner" />
          </Button>
          <br />
          <br />
          <a title="Contribuez" href={contributionUrl} rel="noopener noreferrer" target="_blank">
            <Button color="primary">
              <FormattedMessage id="homepage_btn_contribute" />{' '}
              <i className="text-center nc-icon nc-favourite-28" style={{ fontSize: '20px' }} />
            </Button>
          </a>
          <br />
          <br />
          <a title="Contribuez" href={merchandiseUrl} rel="noopener noreferrer" target="_blank">
            <Button color="primary">
              <FormattedMessage id="partnerpage_shop" />{' '}
              <i className="text-center nc-icon nc-cart-simple" style={{ fontSize: '20px' }} />
            </Button>
          </a>
        </div>
      </Container>
    </div>
  )
}

export default PartnerImageSection
