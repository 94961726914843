import React from 'react'
import { SponsorTier } from 'shared/types'
import SponsorsSectionTier from './SponsorsSectionTier'

interface SponsorsSection {
  sponsorTiers: SponsorTier[]
}

const SponsorsSection = ({ sponsorTiers }: SponsorsSection): JSX.Element => {
  return (
    <>
      {sponsorTiers.map(
        (tier) =>
          tier.sponsors && (
            <SponsorsSectionTier key={tier.id} tier={tier.name} sponsors={tier.sponsors} icon={tier.icon} />
          ),
      )}
    </>
  )
}

export default SponsorsSection
