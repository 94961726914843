import Footer from 'components/Footer'
import Header from 'components/Header'
import PageHelmet from 'components/PageHelmet'
import ScrollToTop from 'components/ScrollToTop'
import PartenariatPage from 'components/PartenariatPage'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { graphql } from 'gatsby'
import { baseUrl, genericdescription, genericTitle, homeImage } from 'shared/constants'
import { PARTENARIAT_PAGE } from 'shared/routes'
import { ContentfulPartenariatPageData, Location } from 'shared/types'
import { mapToGeneralPageFields, mapToSponsorTiers } from 'services'

interface Partenariat {
  location: Location
  data: ContentfulPartenariatPageData
}

const Partenariat = ({ location, data }: Partenariat): JSX.Element => {
  const content = mapToGeneralPageFields(data.contentfulGeneralPageData)
  const sponsorTiers = mapToSponsorTiers(data.allContentfulSponsorTier)
  return (
    <>
      <PageHelmet
        title={genericTitle}
        description={genericdescription}
        url={`${baseUrl}${PARTENARIAT_PAGE}`}
        image={homeImage}
      />
      <ScrollToTop location={location} />
      <ToastContainer />
      <Header location={location} contributionUrl={content.contributionUrl} merchandiseUrl={content.merchandiseUrl} />
      <PartenariatPage
        sponsorTiers={sponsorTiers}
        contributionUrl={content.contributionUrl}
        merchandiseUrl={content.merchandiseUrl}
      />
      <Footer />
    </>
  )
}

export const query = graphql`
  query PartenariatPage($locale: String) {
    allContentfulSponsorTier(filter: { node_locale: { eq: $locale } }, sort: { fields: tier_id, order: ASC }) {
      nodes {
        name
        sponsor {
          name
          url
          image {
            fixed(height: 300, width: 300) {
              src
            }
          }
          contentful_id
        }
        icon
        contentful_id
        tier_id
      }
    }
    contentfulGeneralPageData {
      contentful_id
      contributionUrl
      merchandiseUrl
    }
  }
`

export default Partenariat
