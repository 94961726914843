import React from 'react'
import { Card, CardBody, CardTitle, Col } from 'reactstrap'

interface SponsorCard {
  name: string
  url: string
  image?: string
}

const SponsorCard = ({ name, image, url }: SponsorCard): JSX.Element => {
  return (
    <Col>
      <Card className="sponsor card-profile card-plain ">
        <a className="btn btn-link" href={url} rel="noreferrer" target="_blank">
          <CardBody>
            {image && (
              <div className="card-avatar sponsor-card">
                <img className="lazyload" alt={name} data-src={image} src={image} />
              </div>
            )}
            <CardTitle className="sponsor-title btn btn-link" tag="h4">
              {name}
            </CardTitle>
          </CardBody>
        </a>
      </Card>
    </Col>
  )
}

export default SponsorCard
