import React from 'react'
import { Container } from 'reactstrap'
import { SponsorEntry } from 'shared/types'
import SponsorCard from './SponsorCard'

interface SponsorsSectionTier {
  tier: string
  icon: string
  sponsors: SponsorEntry[]
}

const SponsorsSectionTier = ({ tier, sponsors, icon }: SponsorsSectionTier): JSX.Element => {
  return (
    <>
      <Container>
        <h3 className="title text-center">
          <i className={`text-center nc-icon nc-${icon}`} style={{ fontSize: '50px', color: '#39a3a3' }} />
          <br />
          {tier}
        </h3>
        {sponsors.map((sponsor) => (
          <SponsorCard key={sponsor.id} name={sponsor.name} image={sponsor.image} url={sponsor.url} />
        ))}
        <hr />
        <br />
      </Container>
    </>
  )
}

export default SponsorsSectionTier
