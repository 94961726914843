import React from 'react'
import { SponsorTier } from 'shared/types'
import SponsorsSection from './SponsorsSection'
import PartnerImageSection from './PartnerImageSection'
import PartenariatFormSection from './PartenariatFormSection'

interface PartenariatPage {
  sponsorTiers: SponsorTier[]
  contributionUrl: string
  merchandiseUrl: string
}

const PartenariatPage = ({ sponsorTiers, contributionUrl, merchandiseUrl }: PartenariatPage): JSX.Element => {
  return (
    <>
      <PartnerImageSection contributionUrl={contributionUrl} merchandiseUrl={merchandiseUrl} />
      <div className="main">
        <div className="section text-center ">
          <SponsorsSection sponsorTiers={sponsorTiers} />
          <PartenariatFormSection formRef={null} sponsorTiers={sponsorTiers} />
        </div>
      </div>
    </>
  )
}

export default PartenariatPage
